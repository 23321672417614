<template>
  <div class="auth">
    <div class="auth-wrap">
      <div class="auth-info">
        <div class="auth-info__title">
          <p>{{ $t('authSlogan1') }}</p>
          <p>{{ $t('authSlogan2') }}</p>
          <p>{{ $t('authSlogan3') }}</p>
          <p>{{ $t('authSlogan4') }}</p>
          <p>{{ $t('authSlogan5') }}</p>
        </div>
      </div>
      <div class="auth-content">
        <div class="auth-form">
          <div class="auth-form__header">
            <div class="auth-form__header-default">
              {{ $t('registerToday') }}
            </div>
          </div>
          <div class="auth-steps">
            <div class="auth-steps__title">
              {{ $t('authOption') }}
            </div>
            <div class="auth-steps__list">
              <router-link
                :to="$localize({ name: 'registration' })"
                class="auth-steps__item-wrap">
                <div class="auth-steps__item">
                  <div class="auth-steps__item-title">
                    {{ $t('registerByEmail') }}
                  </div>
                  <div class="auth-steps__item-icon">
                    <icon name="registration"></icon>
                  </div>
                  <div class="auth-steps__item-text">
                    {{ $t('registrationOption') }}
                  </div>
                </div>
              </router-link>
              <a href="#"
                 class="auth-steps__item-wrap"
                 @click.prevent="openQRCodePopup">
                <div class="auth-steps__item">
                  <div class="auth-steps__item-title">
                    {{ $t('loginWithApp') }}
                  </div>
                  <div class="auth-steps__item-icon">
                    <icon name="qrCode"></icon>
                  </div>
                  <div class="auth-steps__item-text">
                    {{ $t('loginWithAppOption') }}
                  </div>
                </div>
              </a>
              <a href="#"
                 class="auth-steps__item-wrap"
                 @click.prevent="openSignInPopup($t('loginByEmail'))">
                <div class="auth-steps__item">
                  <div class="auth-steps__item-title">
                    {{ $t('loginByEmail') }}
                  </div>
                  <div class="auth-steps__item-icon">
                    <icon name="recovery"></icon>
                  </div>
                  <div class="auth-steps__item-text">
                    {{ $t('recoveryOptionText') }}
                  </div>
                </div>
              </a>
            </div>
          </div>
          <MobileAppLinks/>
          <div class="auth-footer">
            <p>
              {{ $t('homeSlider.needMoreInfo') }}
              <a :href="`${privateAppLink}faq`"
                 class="text-decoration-link"
                 target='_blank'
                 rel='noopener noreferrer'>
                {{ $t('homeSlider.learnMore') }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MobileAppLinks from '@/components/atoms/MobileAppLinks.vue'
import Icon from '@/components/atoms/Icon.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    MobileAppLinks,
    Icon
  },
  computed: {
    ...mapGetters({
      otpResponse: 'auth/otpResponse',
      isAuthenticated: 'auth/isAuthenticated',
      socialInfo: 'auth/socialInfo'
    }),
    privateAppLink () {
      return process.env.VUE_APP_PRIVATEAPP_LINK
    }
  },
  methods: {
    openQRCodePopup () {
      this.$modal.show('login-by-code')
    },
    openSignInPopup (title) {
      this.$modal.show('sign-in', { title: title })
    }
  }
}

</script>
<style lang="scss">
.auth {
  position: relative;
  min-height: 80vh;
  background: url('../assets/img/auth-bg.png') center center no-repeat;
  background-size: cover;
  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.35);
  }
  &-wrap{
    min-height: 80vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1600px;
    margin: 0 auto;
    padding: 120px 15px;
    position: relative;
    z-index: 1;
    @include respondTo(1300px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: none;
      padding: 60px 0;
    }
    @include respondTo(600px){
      padding: 40px 0;
      justify-content: center;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    max-width: 630px;
    @include respondTo(650px){
      max-width: 95%;
      width: 100%;
    }
  }
  &-footer{
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 30px;
    color: $white;
    text-align: center;
    @include respondTo(1950px){
      font-size: 24px;
    }
    @include respondTo(1200px){
      font-size: 20px;
    }
    @include respondTo(550px){
      font-size: 16px;
    }
    p{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    p a{
      color: $white;
      text-decoration: none;
      border-bottom: 1px solid $white;
      transition: border-bottom-color $trans, color $trans;
      &:hover {
        border-bottom-color: transparent;
        color: $green;
      }
    }
  }
  &-form{
    &__header{
      padding: 20px 0 0;
      background: $white;
      text-align: center;
      font-weight: 500;
      color: #00b764;
      font-size: 18px;
      line-height: 1.3;
      span{
        display: block;
        font-size: 20px;
        line-height: 1.3;
        @include respondTo(600px){
          font-size: 16px;
        }
      }
    }
  }
  &-info{
    max-width: 800px;
    @include respondTo(1520px){
      max-width: 600px;
      margin-bottom: 40px;
    }
    @include respondTo(600px){
      max-width: 95%;
      margin-bottom: 20px;
    }
    &__title{
      p {
        font-size: 26px;
        line-height: 36px;
        text-align: center;
        color: #fff;
        margin-bottom: 40px;
        @include respondTo(800px){
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 20px;
        }
      }
    }
  }
  &-steps {
    border-top: 10px solid white;
    border-bottom: 40px solid white;
    border-left: 40px solid white;
    border-right: 40px solid white;
    @include respondTo(600px){
      border-left: 10px solid white;
      border-right: 10px solid white;
    }
    &__title{
      padding: 10px 0 40px;
      text-align: center;
      background: $white;
      color: #a0a0a0;
      position: relative;
      @include respondTo(600px){
        transform: translateX(-1px);
        width: calc(100% + 2px);
      }
      &:after{
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: $white;
      }
    }
    &__list{
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      margin-left: -10px;
      margin-right: -10px;
      //background: rgba(0,0,0,0.35);
      @include respondTo(600px){
        background: transparent;
        overflow: hidden;
      }
      @include respondTo(450px){
        flex-direction: column;
      }
    }
    &__item{
      padding: 15px 10px;
      height: 100%;
      &-wrap{
        display: flex;
        flex-direction: column;
        align-self: stretch;
        width: 33.33%;
        border-left: 10px solid $white;
        border-right: 10px solid $white;
        position: relative;
        text-decoration: none;
        &:after{
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.35);
        }
        @include respondTo(450px){
          width: 100%;
          margin: 0;
          padding: 0 15px;
          border-top: 1px solid white;
        }
        &:hover{
          svg {
            fill: $green;
          }
        }
      }
      &-title{
        font-size: 14px;
        line-height: 1.2;
        text-transform: uppercase;
        color: $white;
        text-align: center;
        position: relative;
        z-index: 1;
        min-height: 45px;
        overflow: hidden;
      }
      &-icon{
        margin: 15px 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        min-height: 90px;
        svg{
          vertical-align: middle;
          transition: fill $trans;
        }
      }
      &-text{
        font-size: 14px;
        color: $white;
        line-height: 20px;
        text-align: center;
        position: relative;
        z-index: 1;
        a{
          font-weight: 500;
          color: $white;
        }
      }
    }
  }
}
</style>
